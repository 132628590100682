import React from 'react'
import Link from 'gatsby-link'
import { css } from '@emotion/core'
import Helmet from 'react-helmet'
import Layout from '../components/layout'

// Styles
import common from '../styles/common'
import colors from '../styles/colors'

// Components
import Hero from '../components/hero'
import Button from '../components/button'

// Content
import openPositions from '../content/open-positions'

const LedigeStillingerPage = () => (
  <Layout>
    <div css={container}>
      <Helmet>
        <title>Ekko App - Ledige stillinger</title>
      </Helmet>
      <Hero
        title="Ledige stillinger"
        subTitle="Se her om vi har et job, der passer til dig. Du er altid velkommen til at sende en uopfordret ansøgning."
        secondaryAction="Send uopfordret ansøgning"
        secondaryActionHref="mailto:job@toecho.dk?subject=Uopfordret ansøgning"
        secondaryUseAnchorTag={true}
      />

      <div className="wrapper">
        {openPositions.map((position, positionKey) => (
          <div className="container full-width" key={positionKey}>
            <div className="inner-container">
              <h2 className="u_mb-xs">{position.title}</h2>
              <p>{position.description}</p>
              <Button
                useAnchorTag={true}
                href={
                  'mailto:job@toecho.dk?subject=Ansøgning til ' + position.title
                }
                type="dark fill"
                text="Send ansøgning"
              />
              {position.theHubLink ? (
                <Button
                  rel="noreferrer"
                  target="_blank"
                  useAnchorTag={true}
                  href={position.theHubLink}
                  type="dark outline"
                  text="Se på The Hub"
                />
              ) : null}
            </div>
          </div>
        ))}

        <div className="container full-width">
          <div className="inner-container">
            <h2 className="u_mb-xs">Uopfordret ansøgning</h2>
            <p>
              Vi er et lille passioneret hold, der brænder for det vi laver og
              vi er altid på udkig efter nye talenter. Kig under{' '}
              <Link to="/ledige-stillinger">ledige stillinger</Link> og se, om
              vi har et job, der passer til dig. Vi er løbende på udkig efter
              talenter, så hvis du kan biddrage med noget til vores team er du
              altid velkommen til at sende os en upfordret ansøgning.
            </p>
            <Button
              useAnchorTag={true}
              href="mailto:job@toecho.dk?subject=Uopfordret ansøgning"
              type="dark fill"
              text="Send uopfordret ansøgning"
            />
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

function container() {
  return css`
    h2 {
      color: ${colors.deepSeaBlue};
    }

    .wrapper {
      @media screen and (min-width: ${common.breakpointMedium + 1}px) {
        padding: 100px 0;
      }
      @media screen and (max-width: ${common.breakpointMedium}px) {
        padding: 50px 0 50px 0;
      }

      .container {
        border-bottom: 1px solid ${colors.midGrey};
        padding: 80px 0;

        &:last-child {
          border-bottom: 0px;
        }
      }
    }

    @media screen and (min-width: 501px) {
      button,
      a.button {
        margin: 0 0.5rem 1rem 0;
      }
    }

    @media screen and (max-width: 500px) {
      button,
      a.button {
        display: block;
        margin: 0 0 1rem 0;
      }
    }
    a {
      color: ${colors.seaBlue};
      font-weight: 500;
    }
  `
}

export default LedigeStillingerPage
